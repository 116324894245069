import { formatTime } from "./helper";
import { TextSkeletonLoader } from "../BootcampRoadmap/utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { ButtonLoader } from "../../screens/Resources/Blog/AllBlogposts/TagsButtons";
import { RichText } from "./RichTextEditor";
import { Checkbox } from "@mui/material";
import { useState } from "react";

export const StudentDetails = ({ projectDetails }) => (
  <div className="mt-10">
    <h2 className="mb-5 font-bold text-xl">Student details</h2>
    <div className="text-[#666666] font-normal">
      <strong className="font-extrabold">Full Name:</strong>{" "}
      {!projectDetails ? (
        <TextSkeletonLoader width={`max-w-[9.7rem]`} />
      ) : (
        projectDetails?.fullName
      )}
    </div>
    <div className="text-[#666666] font-normal">
      <strong className="font-extrabold">Bootcamp Cohort:</strong>{" "}
      {!projectDetails ? (
        <TextSkeletonLoader width={`max-w-[5.7rem]`} />
      ) : (
        projectDetails?.bootcampStartDate
      )}
    </div>
  </div>
);

export const CourseDetails = ({ projectDetails }) => (
  <div className="mt-10">
    <h2 className="mb-5 font-bold text-xl">Course details</h2>
    <div className="text-[#666666] font-normal max-w-lg">
      <strong className="font-extrabold">Course:</strong>{" "}
      {!projectDetails ? (
        <TextSkeletonLoader width={`max-w-[25.7rem]`} />
      ) : (
        projectDetails?.courseTitle
      )}
    </div>
    <div className="text-[#666666] font-normal">
      <strong className="font-extrabold">Submission Time:</strong>{" "}
      {!projectDetails ? (
        <TextSkeletonLoader width={`max-w-[6.7rem]`} />
      ) : (
        formatTime(projectDetails?.submissionTimestamp)
      )}
    </div>
  </div>
);

export const ReviewCard = ({ status, setStatus }) => (
  <>
    <h2 className="mb-5 font-bold text-xl">Review</h2>
    <p className="text-[#666666] font-normal max-w-[31.25rem]">
      Select a grading for this student following your review using
      the rubric below:
    </p>

    <div className="max-w-lg mt-5 flex gap-2 justify-between flex-wrap">
      <ApproveProjectCard
        status={status}
        setStatus={setStatus}
      />
      <ProjectRevisionRequiredCard
        status={status}
        setStatus={setStatus}
      />
    </div>
  </>
);

export const ProjectDownloadLink = ({ projectDetails }) => (
  <>
    <div className="flex items-center my-10 relative">
      {(projectDetails &&
        projectDetails?.courseId ===
          "419bde16-d7e7-4c12-8722-964c6db0a6f9") ||
      projectDetails?.courseId ===
        "Excel Practical Challenge Test" ? (
        <FileDownloadIcon className="relative -left-[0.35rem]" />
      ) : (
        <OpenInNewRoundedIcon className="relative -left-[0.35rem]" />
      )}
      <div className="text-black w-[30.5rem] truncate">
        <span className="text-[#666666] font-normal">
          {(projectDetails &&
            projectDetails?.courseId ===
              "419bde16-d7e7-4c12-8722-964c6db0a6f9") ||
          projectDetails?.courseId ===
            "Excel Practical Challenge Test"
            ? "Download:"
            : "Project Link:"}
        </span>{" "}
        {!projectDetails ? (
          <TextSkeletonLoader width={`max-w-[9.7rem]`} />
        ) : (
          <a
            target="__blank"
            href={projectDetails?.submissionLink}
            className="underline font-normal"
          >
            {projectDetails.courseId ===
            "419bde16-d7e7-4c12-8722-964c6db0a6f9"
              ? `${projectDetails.fullName
                  ?.split(" ")
                  .join("+")}_GDP_Analysis.PBIX`
              : projectDetails?.courseId ===
                "Excel Practical Challenge Test"
              ? `${projectDetails.fullName
                  ?.split(" ")
                  .join(
                    "+"
                  )}_Excel_Practical_Challenge_Test.${projectDetails?.submissionLink
                  ?.split(".")
                  .pop()
                  .toUpperCase()}`
              : projectDetails.courseId ===
                "7736c5fb-1846-4a3e-afad-7a970042c37d"
              ? `${projectDetails.fullName
                  ?.split(" ")
                  .join(
                    "+"
                  )}_Data_Analytics_Portfolio_And_Capstone_Project.${projectDetails?.submissionLink
                  ?.split(".")
                  .pop()
                  .toUpperCase()}`
              : projectDetails.courseId ===
                "Baobab Financial Services Job Test"
              ? `${projectDetails.fullName
                  ?.split(" ")
                  .join("+")}_baobab_file`
              : `${projectDetails.fullName
                  ?.split(" ")
                  .join("+")}_Google_sheets`}
          </a>
        )}
      </div>
    </div>

    {projectDetails?.githubRepoLink && (
      <div className="flex items-center -mt-6 mb-10 relative">
        <OpenInNewRoundedIcon className="relative -left-[0.35rem]" />
        {projectDetails?.courseId ===
          "7736c5fb-1846-4a3e-afad-7a970042c37d" && (
          <div
            title={`${projectDetails?.githubRepoLink}`}
            className="text-black w-[30.5rem] truncate"
          >
            <span className="text-[#666666] font-normal">
              GitHub repo link:
            </span>{" "}
            {!projectDetails ? (
              <TextSkeletonLoader width={`max-w-[9.7rem]`} />
            ) : (
              <a
                target="__blank"
                href={projectDetails?.githubRepoLink}
                className="underline font-normal"
              >
                {`${projectDetails?.githubRepoLink}`}
              </a>
            )}
          </div>
        )}
      </div>
    )}
  </>
);

export const StudentComment = ({ projectDetails }) => {
  const renderHTML = (html) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
    return { __html: doc?.body.outerHTML };
  };

  return (
    <div className="text-[#666666]">
      <strong className="font-extrabold">Student Comment:</strong>
      <div className="w-full mt-5 min-h-[14.438rem] max-w-lg bg-[#fafafa] rounded p-4">
        {!projectDetails ? (
          <>
            <TextSkeletonLoader width={`max-w-[23.7rem]`} />
            <TextSkeletonLoader width={`max-w-[19.7rem]`} />
            <TextSkeletonLoader />
            <TextSkeletonLoader width={`max-w-[25.7rem]`} />
          </>
        ) : (
          <div
            className="student_comments"
            dangerouslySetInnerHTML={renderHTML(
              projectDetails.comment
            )}
          />
        )}
      </div>
    </div>
  );
};

export const ApproveProjectCard = ({ status, setStatus }) => (
  <div
    onClick={() =>
      setStatus({
        approved: status.approved ? false : true,
        revisionRequired: false,
      })
    }
    className={`w max-w-[15.2rem] px-2 ${
      status.approved ? "bg-[#bcecd5]" : "bg-[#f2f2f2]"
    } rounded py-4 pr-8 min-h-[17.014rem] flex-1`}
  >
    <h3 className="font-semibold text-base mb-2">Approved</h3>
    <ul className="list-disc pl-6">
      <li className="text-sm font-medium">
        Demonstrate thorough understanding of project requirements.
      </li>
      <li className="text-sm font-medium">
        Meets or exceed the minimum 70% benchmark for passing a grade
      </li>
      <li className="text-sm font-medium">
        Comprehensive and well-executed project, serving as an
        exemplary model for others.
      </li>
    </ul>
  </div>
);

export const ProjectRevisionRequiredCard = ({
  status,
  setStatus,
}) => (
  <div
    onClick={() =>
      setStatus({
        approved: false,
        revisionRequired: status.revisionRequired ? false : true,
      })
    }
    className={`w max-w-[15.2rem] px-2 ${
      status.revisionRequired ? "bg-[#ffd9ab]" : "bg-[#f2f2f2]"
    } rounded py-4 pr-8 min-h-[17.014rem] flex-1`}
  >
    <h3 className="font-semibold text-base mb-2">
      Revision Required
    </h3>
    <ul className="list-disc pl-6">
      <li className="text-sm font-medium">
        Indicates incomplete understanding or execution of project
        requirements
      </li>
      <li className="text-sm font-medium">
        Fails below the 70% minimum threshold for project approval
      </li>
      <li className="text-sm font-medium">
        Specific section of the project require significant review and
        improvement.
      </li>
      <li className="text-sm font-medium">
        Incomplete or unsatisfactory elements need attention for a
        successful resubmission.
      </li>
    </ul>
  </div>
);

export const AdditionalFeedback = ({
  addStory,
  setAddStory,
  additionalFeedback,
  setAdditionalFeedback,
}) => {
  return (
    <div className="text-[#666666] my-10">
      <div className="flex justify-between flex-wrap w-full max-w-lg">
        <strong className="font-extrabold">
          Additional Feedback
        </strong>
        <div className="outline-1 outline pl-2 rounded-md">
          <label
            htmlFor="add_to_stories"
            className="cursor-pointer"
          >
            Add to stories
          </label>
          <Checkbox
            id="add_to_stories"
            checked={addStory}
            onChange={(e) => {
              setAddStory(e.currentTarget.checked);
            }}
          />
        </div>
      </div>
      <div>
        <RichText
          additionalFeedback={additionalFeedback}
          setAdditionalFeedback={setAdditionalFeedback}
        />
      </div>
    </div>
  );
};

export const SubmitProjectReview = ({
  isLoading,
  handleSubmitProject,
}) => (
  <div>
    <button
      disabled={isLoading}
      onClick={handleSubmitProject}
      className="px-4 rounded font-normal text-white bg-[#2b2c54] py-1 relative"
    >
      Submit
      {isLoading && <ButtonLoader />}
    </button>
  </div>
);
