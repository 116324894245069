// Styles
import "./App.css";

import dayjs from "dayjs";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
// import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

// route
import UserPrivateRoute from "./route/UserPrivateRoute";
import DashboardPrivateRoute from "./route/DashboardPrivateRoute";
import AuthenticatedAuthorPrivateRoute from "./route/AuthorPrivateRoute";
import AuthenticatedPrivateRoute from "./route/AuthenticatedPrivateRoute";
import ActivationFormPrivateRoute from "./route/ActivationFormPrivateRoute";
import PaymentSuccessPrivateRoute from "./route/PaymentSuccessPrivateRoute";

// components
import Layout from "./utils/Layout/Layout";
import NotFound from "./components/NotFound/NotFound";
import ComingSoon from "./components/ComingSoon/ComingSoon";

// utils
import Loader from "./utils/Loader/Loader";
import {
  handleAuthorRole,
  handleSetPremiumRole,
  handleAwaitingAccess,
} from "./redux/reducers/setUserSubscriptionSlice";
import CountdownTimer from "./utils/CountdownTimer/CountdownTimer";

// screens
import LearnMenu from "./screens/LearnMenu/LearnMenu";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";
import CoursesMenu from "./screens/CoursesMenu/CoursesMenu";
import UserProfile from "./screens/UserProfile/UserProfile";
import LearningPath from "./screens/LearningPath/LearningPath";
import UserSettings from "./screens/UserSettings/UserSettings";
import EmailVerified from "./screens/EmailVerfied/EmailVerified";
import CourseDetails from "./screens/CourseDetails/CourseDetails";
import UserAchievements from "./screens/Achievements/Achievements";
import SqlAnalystPath from "./screens/SqlAnalystPath/SqlAnalystPath";
import PaymentSuccess from "./screens/PaymentSuccess/PaymentSuccess";
import BootcampPayment from "./screens/BootcampPayment/BootcampPayment";
import ExcelAnalystPath from "./screens/ExcelAnalystPath/ExcelAnalystPath";
import ProjectSubmission from "./screens/ProjectSubmission/ProjectSubmission";
import PowerbiAnalystPath from "./screens/PowerbiAnalystPath/PowerbiAnalystPath";
import TableauAnalystPath from "./screens/TableauAnalystPath/TableauAnalystPath";
import ProjectSubmissionPrivateRoute from "./route/ProjectSubmissionPrivateRoute";
import BootcampLearningPath from "./screens/BootcampLearningPath/BootcampLearningPath";
import UserSubscriptionSettings from "./screens/UserSubscriptionSettings/UserSubscriptionSettings";
import { useAuth } from "./context/AuthContext";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase/firebase";
import { handleBootcampStartDate } from "./redux/reducers/bootcampStartDate";
import { handleBootcampType } from "./redux/reducers/dashboardDataSlice";

// lazy loading
const CareerPathQuiz = lazy(() =>
  import("./screens/CareerPathQuiz/CareerPathQuiz")
);
const SuccessStories = lazy(() =>
  import("./components/SuccessStories/SuccessStories.jsx")
);
const Assessment = lazy(() =>
  import("./screens/Assessment/Assessmnet")
);
const GraduateStory = lazy(() =>
  import("./screens/GraduateStory/GraduateStory")
);
const DataAnalytics = lazy(() =>
  import("./screens/DataAnalytics/DataAnalytics")
);
const DataScience = lazy(() =>
  import("./screens/DataScience/DataScience")
);
const Shop = lazy(() => import("./screens/shop/Shop"));
const ImpactReport = lazy(() => import("./screens/ImpactReport"));

const TermsOfUse = lazy(() =>
  import("./screens/TermsOfUse/TermsOfUser.jsx")
);

const PaymentOptions = lazy(() =>
  import("./screens/PaymentOptions/PaymentOptions")
);

const DatasetsItem = lazy(() =>
  import("./components/DatasetsItem/DatasetsItem")
);

const ContactForm = lazy(() =>
  import("./screens/ContactForm/ContactForm")
);

const AllBlogposts = lazy(() =>
  import("./screens/Resources/Blog/AllBlogposts/AllBlogposts")
);

const SignIn = lazy(() => import("./screens/SignIn/SignIn"));

const SignUp = lazy(() => import("./screens/SignUp/SignUp"));

const ForgotPassword = lazy(() =>
  import("./screens/ForgotPassword/ForgotPassword")
);

const UserSignupDetails = lazy(() =>
  import("./screens/UserSignupDetails/UserSignupDetails")
);

const Datasets = lazy(() =>
  import("./screens/Resources/Datasets/Datasets")
);

const SingleDashboardPost = lazy(() =>
  import("./screens/Products/SingleDashboardPost")
);

const GraduateProfile = lazy(() =>
  import("./screens/Graduates/GraduateProfile/GraduateProfile")
);

const DataDigest = lazy(() =>
  import(
    "./screens/Resources/DataDigest/AllDataDigestPosts/DataDigest"
  )
);

const SingleDataDigestPost = lazy(() =>
  import(
    "./screens/Resources/DataDigest/SingleDataDigestPost/SingleDataDigestPost"
  )
);

const SingleBlogpost = lazy(() =>
  import("./screens/Resources/Blog/SingleBlogpost/SingleBlogpost")
);

const AdminView = lazy(() => import("./screens/AdminView/AdminView"));

const AuthorDashboard = lazy(() =>
  import("./screens/AuthorDashboard/AuthorDashboard")
);

const About = lazy(() => import("./screens/About/About"));

const Pay = lazy(() => import("./screens/Pay/Pay"));

const BootcampLandingPage = lazy(() =>
  import("./screens/BootcampLandingPage/BootcampLandingPage")
);

const Graduates = lazy(() => import("./screens/Graduates/Graduates"));

const LandingPage = lazy(() =>
  import("./screens/LandingPage/LandingPage")
);

const ContentViewer = lazy(() =>
  import("./screens/ContentViewer/ContentViewer")
);

const WelcomeDashboard = lazy(() =>
  import("./screens/UserDashboard/UserDashboard")
);

const EmailVerification = lazy(() =>
  import("./screens/EmailVerification/EmailVerification")
);

// const PythonConsole = lazy(() =>
//   import("./screens/PythonConsole/PythonConsole")
// );

const App = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  // const functions = getFunctions(getApp());

  /**Uncomment this only in development if working
   * on anything related to Firebase Cloud functions.
   * For this to work make sure you've alread ran the
   * command "npm run serve" on the backend repo after
   * you changed directory (cd) into functions directory.
   */
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

  const isPageId = useSelector(
    (state) => state.dailyCountdownTimer.checkPageId
  );
  const roleCheck = useSelector(
    (state) => state.userSubscription.runRoleCheck
  );
  const userHasPaidRole = useSelector(
    (state) => state.userSubscription.premiumUser
  );

  const tomorrow = 1 * 24 * 60 * 60 * 1000;
  const todayN = new Date().getTime();

  const ttl = todayN + tomorrow;

  useEffect(() => {
    (async () => {
      try {
        if (!currentUser) return;

        const today = dayjs();

        const bootcampQuery = query(
          collection(db, "bootcampers"),
          orderBy("firestoreTimestamp", "desc"),
          where("email", "==", currentUser.email),
          limit(1)
        );

        const bootcampSnapshot = await getDocs(bootcampQuery);
        const bootcampDoc = bootcampSnapshot?.docs;

        if (bootcampDoc?.length > 0) {
          const startDate =
            bootcampDoc[0].data()?.bootcampStartDateString;
          const isAwaitingAccess = today.isBefore(startDate);
          const bootcampType = bootcampDoc[0].data()?.bootcampType;
          dispatch(handleBootcampType(bootcampType));

          dispatch(handleBootcampStartDate(startDate));
          dispatch(handleAwaitingAccess(isAwaitingAccess));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    if (roleCheck === true) {
      dispatch(handleSetPremiumRole(true));
    }

    firebase
      .auth()
      .currentUser?.getIdTokenResult(true)
      .then((idTokenResult) => {
        if (idTokenResult?.claims?.isAuthor)
          dispatch(handleAuthorRole(true));
        if (idTokenResult.claims.paidSubscriber === true) {
          dispatch(
            handleSetPremiumRole(idTokenResult.claims.paidSubscriber)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, roleCheck, userHasPaidRole]);

  return (
    <>
      <HelmetProvider>
        <Suspense fallback={<Loader />}>
          {isPageId === true && <CountdownTimer ttl={ttl} />}
          <ScrollToTop>
            <Routes>
              <Route
                to="/"
                element={<Layout />}
              >
                <Route
                  path="quiz"
                  element={<CareerPathQuiz />}
                />
                <Route
                  path="success-stories"
                  element={<SuccessStories />}
                />
                <Route
                  path="assessment/:name"
                  element={
                    <UserPrivateRoute>
                      <Assessment />
                    </UserPrivateRoute>
                  }
                />
                <Route
                  path="shop"
                  element={
                    <UserPrivateRoute>
                      <Shop />
                    </UserPrivateRoute>
                  }
                />
                <Route
                  index
                  element={<LandingPage />}
                />
                <Route
                  path="*"
                  element={<NotFound />}
                />
                <Route
                  path="signin"
                  element={<SignIn />}
                />
                <Route
                  path="signup"
                  element={<SignUp />}
                />
                <Route
                  path="forgot-password"
                  element={
                    <AuthenticatedPrivateRoute>
                      <ForgotPassword />
                    </AuthenticatedPrivateRoute>
                  }
                />
                <Route
                  path="learn"
                  element={<LearnMenu />}
                >
                  <Route
                    index
                    element={<LearningPath />}
                  />
                  <Route
                    path="learning-path"
                    element={<LearningPath />}
                  >
                    <Route
                      path="excel-analyst-path"
                      element={<ExcelAnalystPath />}
                    />
                    <Route
                      path="powerbi-analyst-path"
                      element={<PowerbiAnalystPath />}
                    />
                    <Route
                      path="tableauviz-analyst-path"
                      element={<TableauAnalystPath />}
                    />
                    <Route
                      path="sql-analyst-path"
                      element={<SqlAnalystPath />}
                    />
                  </Route>
                  <Route
                    path=":courseId"
                    element={<CoursesMenu />}
                  />
                </Route>
                <Route
                  path="course/:courseName"
                  element={<CourseDetails />}
                />
                <Route
                  path="learn/*"
                  element={<ComingSoon />}
                />
                <Route
                  path="products/:slug"
                  element={<SingleDashboardPost />}
                />
                <Route
                  path="products/*"
                  element={<ComingSoon />}
                />
                <Route
                  path="resources/datasets"
                  element={<Datasets />}
                />
                <Route
                  path="resources/datasets/:id"
                  element={<DatasetsItem />}
                />
                <Route
                  path="resources/blog"
                  element={<AllBlogposts />}
                />
                {/* <Route
                  path="console/python"
                  element={<PythonConsole />}
                /> */}
                <Route
                  path="resources/reports/impact-report-2023"
                  element={<ImpactReport />}
                />
                <Route
                  path="/resources/blog/:slug"
                  element={<SingleBlogpost />}
                />
                <Route
                  path="/resources/datadigest"
                  element={<DataDigest />}
                />
                <Route
                  path="/resources/datadigest/:slug"
                  element={<SingleDataDigestPost />}
                />
                <Route
                  path="user-details"
                  element={
                    <ActivationFormPrivateRoute>
                      <UserSignupDetails />
                    </ActivationFormPrivateRoute>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <DashboardPrivateRoute>
                      <WelcomeDashboard />
                    </DashboardPrivateRoute>
                  }
                />
                <Route
                  path="library/:name"
                  element={
                    <UserPrivateRoute>
                      <ContentViewer />
                    </UserPrivateRoute>
                  }
                />
                <Route
                  path="account"
                  element={
                    <UserPrivateRoute>
                      <UserSettings />
                    </UserPrivateRoute>
                  }
                >
                  <Route
                    path="profile"
                    index
                    element={<UserProfile />}
                  />
                  <Route
                    path="subscription"
                    element={<UserSubscriptionSettings />}
                  />
                  <Route
                    path="achievements"
                    element={<UserAchievements />}
                  />
                </Route>
                <Route
                  path="verify-email"
                  element={<EmailVerification />}
                />
                <Route
                  path="verify-email/success"
                  element={<EmailVerified />}
                />
                <Route
                  path="pricing"
                  element={<PaymentOptions />}
                />
                <Route
                  path="pay"
                  element={<Pay />}
                />
                <Route
                  path="contact-us"
                  element={<ContactForm />}
                />
                <Route
                  path="about"
                  element={<About />}
                />
                <Route
                  path="bootcamp"
                  element={<BootcampLandingPage />}
                />
                <Route
                  path="bootcamp/data-analytics"
                  element={<DataAnalytics />}
                />
                <Route
                  path="bootcamp/data-science"
                  element={<DataScience />}
                />
                <Route
                  path="bootcamp/paths"
                  element={<BootcampLearningPath />}
                />
                <Route
                  path="dashboard/author"
                  element={
                    <AuthenticatedAuthorPrivateRoute>
                      <AuthorDashboard />
                    </AuthenticatedAuthorPrivateRoute>
                  }
                />
                <Route
                  path="dashboard/admin"
                  element={
                    <ProjectSubmissionPrivateRoute>
                      <AdminView />
                    </ProjectSubmissionPrivateRoute>
                  }
                />
                <Route
                  path="dashboard/admin/:docId"
                  element={
                    <ProjectSubmissionPrivateRoute>
                      <ProjectSubmission />
                    </ProjectSubmissionPrivateRoute>
                  }
                />
                <Route
                  path="bootcamp/bootcamp-signup"
                  element={<BootcampPayment />}
                />
                <Route
                  path="bootcamp/graduates"
                  element={<Graduates />}
                />
                <Route
                  path="bootcamp/graduates/stories/:slug"
                  element={<GraduateStory />}
                />
                <Route
                  path="bootcamp/graduates/:id"
                  element={<GraduateProfile />}
                />
                <Route
                  path="payment-success/:transactionId"
                  element={
                    <PaymentSuccessPrivateRoute>
                      <PaymentSuccess />
                    </PaymentSuccessPrivateRoute>
                  }
                />
                <Route
                  path="terms-of-use"
                  element={<TermsOfUse />}
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </Suspense>
      </HelmetProvider>
    </>
  );
};

export default App;
